import Tag from "./Tag";

function ExperienceCard(props) {
    const experiencedetail = props.experiencedetail;

    return (
        <div key={experiencedetail.key} className="experience-container">
            <p>{experiencedetail.year}</p>
            <h3>{experiencedetail.position}</h3>
            <p>{experiencedetail.company}</p>
            <p className="exp-details">{experiencedetail.exp}</p>
            <div className="tag-container">
                {experiencedetail.isVol && <Tag label="Voluntary" />}
            </div>
        </div>
    )
}

export default ExperienceCard