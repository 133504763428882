import SkillCard from "./SkillCard";

function Skills() {
    const skillsets = [
        {
            type: "Languages",
            includes: ["Turkish (Native)", "English (Advanced)", "French (Advanced)", "German (Intermediate)", "Spanish (Intermediate)", "Ancient Greek (Academic interest)", "Latin (Academic interest)"],
        },
        {
            type: "Organization",
            includes: ["Systems Thinking", "Project Management"],
        },
        {
            type: "Development",
            includes: ["HTML", "CSS", "JS (ES6)", "React", "Python", "Kivy", "Git", "GitHub"],
        },
        /*
        {
            type: "Tools",
            includes: ["Bash", "Git", "GitHub", "Dev Tools", "VS Code", "VMware Fusion"],
        },*/
        {
            type: "Design",
            includes: ["Figma", "Adobe Xd", "Adobe Ai", "Adobe Ps", "Wireframing", "Prototyping"],
        },
        {
            type: "Utilities",
            includes: ["MS Office", "Libre Office", "Google Suite"],
        },
      ];

    return (
        <section id="skills-section">
            <h2>Skills</h2>
            {skillsets.map((skillset) => (
                <SkillCard key={skillset.type} skillset={skillset}/>
                ))}
        </section>
    )
}

export default Skills