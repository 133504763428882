import ExperienceCard from "./ExperienceCard";

function Experience() {

    const experiences = [
        {
          key: "exp08",
          year: "March 2024-Sep 2024",
          position: "IT Operations Specialist",
          company: "Arrow Partners SA, Hedge Fund & Wealth Management",
          exp: "Maintained and evolved core IT systems, developed AI-powered automation solutions and drove digital transformation across operations",
          isVol: false,
        },
        {
          key: "exp07",
          year: "Aug 2022-Present",
          position: "Board Member, Scholarship Committee Member",
          company: "EFFL Alumni Assosication",
          exp: "Responsible for PR functions, contributing to the delivery of scholarships to students",
          isVol: true,
        },
        {
          key: "exp06",
          year: "Jul 2022, Dec 2022, Jun 2023, May 2024",
          position: "Delegate Guide",
          company: "Turkish Volleyball Federation (TVF), Fédération Internationale de Volleyball (FIVB)",
          exp: "As part of several international events in women's volleyball, assisted the international delegation in organization, logistics and translation",
          isVol: true,
        },
        {
          key: "exp05",
          year: "Oct 2022-Sep 2023",
          position: "Co-Founder, Developer, Designer",
          company: "Nekodu Technology LLC, Izmir, Türkiye",
          exp: "Funded by TÜBİTAK Entrepreneurship Support Program (BiGG) for an automatic boardgame transcription project working with Artificial Intelligence",
          isVol: false,
        },
        {
          key: "exp04",
          year: "May 2019-Oct 2022",
          position: "UX/UI Designer, Front-End Developer",
          company: "Self-employed",
          exp: "Handled and took part several in UX, visual design, and web & mobile development projects",
          isVol: false,
        },
        {
          key: "exp03",
          year: "Aug 2018-Apr 2019",
          position: "Cloud & System Assistant Specialist",
          company: "Borusan Consultancy, Istanbul, Türkiye",
          exp: "Handled development, monitoring and maintenance tasks in a 2k-vm environment",
          isVol: false,
        },
        {
          key: "exp02",
          year: "Sep 2016-Jun 2017",
          position: "Systems Design Project Member",
          company: "EnerjiSA Baskent Electricity Distribution Company, Ankara, Türkiye",
          exp: "Team of 5, analyzed the effects of weather on the electricity distribution network employing data tools",
          isVol: false,
        },
        {
          key: "exp01",
          year: "Apr 2018-May 2018",
          position: "Teaching Assistant",
          company: "METU Continuing Education Center (CEC)",
          exp: "Assisted in a course teaching Python to schoolchildren",
          isVol: true,
        },
      ];

    return (
        <section id="experience-section">
            <h2>Experience</h2>
            {experiences.map((experiencedetail) => (
                <ExperienceCard key={experiencedetail.key} experiencedetail={experiencedetail}/>
                ))}
        </section>
    )
}

export default Experience