import About from "./About";
import Skills from "./Skills";
import Education  from "./Education";
import Experience from "./Experience";
import Awards from "./Awards";
import Certifications from "./Certifications";
import Works from "./Works";
import Footer from "./Footer";

function Main() {

    return (
        <div id="maindiv" className="main">
            <About />
            <Skills />
            <Education />
            <Experience />
            <Awards />
            <Certifications />
            <Works />
            <Footer />
        </div>
    )
}

export default Main