function EducationCard(props) {
    const educationdetail = props.educationdetail;

    return (
        <div key={educationdetail.key} className="education-container">
            <p>{educationdetail.year}</p>
            <h3>{educationdetail.school}</h3>
            <p>{educationdetail.program}</p>
            {educationdetail.extra && <p>{educationdetail.extra}</p>}
        </div>
    )
}

export default EducationCard