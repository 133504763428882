import Tag from "./Tag";

function WorkCard(props) {
    const project = props.project;

    return (
        <div className="work-container">
            <img className="project-thumbnail" src={project.getImageSrc()} alt={project.name} />
            <h3>{project.name}</h3>
            <p>{project.exp}</p>
            <div className="tag-container">
                {project.skills.map((skill) => (
                    <Tag key={skill} label={skill}/>
                ))}
            </div>
            {project.url && <a href={project.url} target="_blank" className="link-inside">More →</a>}
        </div>
    )
}

export default WorkCard