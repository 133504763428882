function Footer() {
    return (
        <footer>
            <p>Copyright 2024 Tunahan Akbulut.</p>
            <p>Designed in Figma. Developed in VS Code. Built in React.</p>
            <p>All rights reserved.</p>
        </footer>
    )
}

export default Footer