import Tag from "./Tag";

function SkillCard(props) {
    const skillset = props.skillset;

    return (
        <div className="skill-container">
            <h3>{skillset.type}</h3>
            <div className="tag-container">
                {skillset.includes.map((oneskill) => (
                    <Tag key={oneskill} label={oneskill}/>
                ))}
            </div>
        </div>
    )
}

export default SkillCard
