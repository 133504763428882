import Main from "./components/Main";
import Sidebar from "./components/Sidebar";

import './App.css';

function App() {

  return (
    <>
      <Sidebar />
      <Main />
    </>
  );
}

export default App;
