import Tag from "./Tag";


function CertCard(props) {
    const cert = props.cert;

    return (
        <div key={cert.name} className="cert-container">
            <p>{cert.date}</p>
            <h3>{cert.name}</h3>
            <p>{cert.platform}</p>
            <div className="tag-container">
            {cert.skills.map((skill) => (
                <Tag key={skill} label={skill}/>
                ))}
            </div>
            {cert.url && <a href={cert.url} target="_blank" className="link-inside">Show credential →</a>}
        </div>
    )
}

export default CertCard
