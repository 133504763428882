import Tag from "./Tag";

function AwardCard(props) {
    const awarddetail = props.awarddetail;

    return (
        <div key={awarddetail.key} className="award-container">
            <p>{awarddetail.year}</p>
            <h3>{awarddetail.name}</h3>
            <p><i>Issued by: {awarddetail.issued}</i></p>
            <p>Related: {awarddetail.related}</p>
            <p className="exp-details">{awarddetail.exp}</p>
        </div>
    )
}

export default AwardCard