import Logo from "../assets/logo.svg";
import inIcon from "../assets/linkedin.svg";
import gitIcon from "../assets/github.svg";
import instaIcon from "../assets/instagram.svg";
import mailIcon from "../assets/email.svg";
import menuIcon from "../assets/menu.svg";
import closeMenu from "../assets/close.svg";

const socials = [
    {
      title: "Linkedin",
      url: "https://linkedin.com/in/yazituna",
      img: inIcon,
    },
    {
        title: "GitHub",
        url: "https://github.com/yazituna",
        img: gitIcon,
    },
    {
        title: "Instagram",
        url: "https://instagram.com/yazituna",
        img: instaIcon,
    },
    {
        title: "Email",
        url: "mailto:akbulut.tun@gmail.com",
        img: mailIcon,
    },
  ];

function Sidebar() {

    const handleClick = (anchor) => () => {
        const id = `${anchor}`;
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        const x = document.getElementById('mmenu');
        const y = document.getElementById('hamburger-icon');
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (vw < 992) {
            x.style.display = "none";
            y.src = menuIcon;
        }
      };

    const mobileMenuFunction = (anchor) => () => {
        const id = `${anchor}`
        const x = document.getElementById(id);
        const y = document.getElementById('hamburger-icon');
        if (x.style.display === "block") {
          x.style.display = "none";
          y.src = menuIcon;
        } else {
          x.style.display = "block";
          y.src = closeMenu;
        }
      }

    return (
        <div className="sidebar-container">
            <div className="logo-name-container">
                <a href="#about" className="logo-container-link" onClick={handleClick('maindiv')}><img src={Logo} className="logo"></img></a>
                <h1>Tunahan Akbulut</h1>
            </div>
            <p className="learner-container">Philosophy. Engineering.</p>
            <div id="mmenu" className="mobile-nav">
                <nav>
                    <a href="#about" onClick={handleClick('about-section')}>About</a>
                    <a href="#skills" onClick={handleClick('skills-section')}>Skills</a>
                    <a href="#education" onClick={handleClick('education-section')}>Education</a>
                    <a href="#experience" onClick={handleClick('experience-section')}>Experience</a>
                    <a href="#awards" onClick={handleClick('awards-section')}>Awards</a>
                    <a href="#certifications" onClick={handleClick('certifications-section')}>Certifications</a>
                    <a href="#works" onClick={handleClick('works-section')}>Works</a>
                </nav>
                <div className="social-icons-container">
                    {socials.map((social) => (
                    <a key={social.title} href={social.url} className="social-links" target='_blank'>
                    <img className="social-icon" src={social.img} alt={social.title} />
                    </a>
                    ))}
                </div>
            </div>
            <a href={undefined} className="menu-icon" onClick={mobileMenuFunction('mmenu')}>
                <img id="hamburger-icon" src={menuIcon} />
            </a>
        </div>
    )
}

export default Sidebar