import AwardCard from "./AwardCard";

function Awards() {

    const awards = [
        {
          key: "awa03",
          year: "June 2024",
          name: "Jean Monnet Scholarship",
          issued: "Republic of Türkiye, Ministry of Foreign Affairs, Directorate for EU Affairs",
          related: "M.A. in Philosophy of Data and Digital Society at Tilburg University",
          exp: "Launched in 1989, a prestigious Turkish-EU initiative offering scholars opportunities to study EU-related subjects in EU countries",
        },
        {
          key: "awa02",
          year: "Oct 2022",
          name: "TUBITAK BIGG - 1512 Entrepreneurship Support Program",
          issued: "Scientific and Technological Research Council of Türkiye",
          related: "Co-Founder at Nekodu Technology LLC",
          exp: "Supports innovative entrepreneurs, focusing on tech projects with global potential, from concept to market launch",
        },
        {
          key: "awa01",
          year: "June 2012",
          name: "Cahit Arf Excellence Scholarship",
          issued: "Republic of Türkiye, Ministry of Youth and Sports",
          related: "Ranked 7th among 2M participants in the national Higher Education Institutions Exam",
          exp: "A prestigious award reserved for students who demonstrate exceptional performance by placing among the top 100 scorers of the national Higher Education Institutions Exam",
        },
      ];

    return (
        <section id="awards-section">
            <h2>Awards</h2>
            {awards.map((awarddetail) => (
                <AwardCard key={awarddetail.key} awarddetail={awarddetail}/>
                ))}
        </section>
    )
}

export default Awards