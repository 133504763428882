import CertCard from "./CertCard";

function Certifications() {

    const certs = [
        {
          date: "Oct 2023",
          name: "Meta Front-End Developer Professional Certificate",
          platform: "By META on Coursera",
          skills: ["HTML", "CSS", "JS", "React", "Git", "GitHub", "VS Code", "Wireframing", "Prototyping"],
          url: "https://www.coursera.org/account/accomplishments/specialization/certificate/APM4MHVF2SBR",
        },
        {
          date: "Feb 2023",
          name: "Google UX Design Professional Certificate",
          platform: "By Google on Coursera",
          skills: ["UX Research", "UX Design", "Wireframing", "Mockups", "Prototyping", "Figma", "Adobe Xd"],
          url: "https://www.coursera.org/account/accomplishments/specialization/certificate/NMWWSBE8LZPG",
        },
        {
          date: "Jun 2021",
          name: "Graphic Design Specialization",
          platform: "By CalArts on Coursera",
          skills: ["Graphic Design", "Typography", "Imagemaking", "Branding", "Adobe Ps", "Adobe Ai", "Adobe Id"],
          url: "https://www.coursera.org/account/accomplishments/specialization/certificate/AC3X4CLNCFLT",
        },
        /* {
          date: "Oct 2020",
          name: "UI / UX Design Specialization",
          platform: "By CalArts on Coursera",
          skills: ["UX Research", "UX Design", "Wireframing", "Moodboards", "Mockups", "Prototyping", "Adobe Ai", "Adobe Xd"],
          url: "https://www.coursera.org/account/accomplishments/specialization/certificate/66Y2RKRBZEKP",
        }, */
        {
          date: "May 2018",
          name: "Introduction to Programming Using Python",
          platform: "By METU CEC",
          skills: ["Python", "Atom"],
          url: false,
        },
        {
          date: "May 2018",
          name: "METU CNA Network",
          platform: "By METU CEC",
          skills: ["Network Fundamentals"],
          url: false,
        },
      ];

    return (
        <section id="certifications-section">
            <h2>Certifications</h2>
            {certs.map((cert) => (
                <CertCard key={cert.name} cert={cert}/>
                ))}
        </section>
    )
}

export default Certifications